// Dependencies
import React, { useEffect } from "react"
import { Link } from "gatsby";
// import PropTypes from "prop-types";
// Hooks & Helpers
import useLineItem from '../hooks/useLineItem'
import useSettings from '../hooks/useSettings'
import priceLabel from '../helpers/priceLabel'
import { trackInitCheckout, trackBeginCheckout } from '../helpers/customAnalyticsEvents'
// Wrappers
import Layout from "../wrappers/Layout"
import { useStoreContext } from "../wrappers/Store"
// Components
import RichText from "../components/RichText";

const QuantityInput = ({ id, quantity }) => {

	const { checkout, updateLineItem, loading } = useStoreContext()

	const increase = () => {
		if (!loading) {
			updateLineItem(checkout.id, id, parseInt(quantity + 1))
		}
	}

	const decrease = () => {
		if (!loading) {
			updateLineItem(checkout.id, id, parseInt(quantity - 1))
		}
	}

	return (
		<div className="quantity-input">
			<button className="btn--square" onClick={decrease}>-</button>
			<button className="btn--square no-border">{quantity}</button>
			<button className="btn--square" onClick={increase}>+</button>
		</div>
	)
}

const LineItem = (props) => {

	// Props
	const { id, variant, quantity, handleRemove } = props

	// Content
	const { title, handle, colourMaterial, size } = useLineItem(props)
	const priceOfQuantity = priceLabel(parseInt(variant.price.amount, 10) * quantity)

	// UI
	// const handleClick = () => {
	// 	handleRemove(id)
	// }

	return (
		<div className="prel block outerx2">
			<div className="grid">
				<div className="span-2">
					<Link to={`/product/${handle}`}>
						<img src={variant.image.src} />
					</Link>
				</div>
				<div className="span-4">
					<h2>{title}</h2>
					<p className="p3">
						{colourMaterial} <br />
						Size: {size}
					</p>
				</div>
				<div className="span-3 cart__quantity">
					<QuantityInput id={id} quantity={quantity} />
					{/* <button className="btn p3 outerx2" onClick={handleClick}>✕ Remove from cart</button> */}
				</div>
				<div className="span-3">
					<p>{priceOfQuantity}</p>
				</div>
			</div>
		</div>
	)
}

function useCheckoutUrl(webUrl) {
	if (!webUrl) return ''
	const chunks = webUrl.split(process.env.GATSBY_SHOPIFY_STORE_URL)
	return `https://shop.songforthemute.com${chunks[1]}`
}

const Cart = ({ pageContext }) => {

	// Context
	const { checkout, removeLineItem } = useStoreContext()
	// const hasLineItems = checkout?.lineItems?.length > 0

	// Content
	const subtotal = priceLabel(checkout?.lineItemsSubtotalPrice?.amount)
	const checkoutLink = useCheckoutUrl(checkout?.webUrl)
	const { cartSmallPrint } = useSettings()

	const handleRemove = (id) => {
		removeLineItem(checkout.id, [id])
	}

	useEffect(() => {
		if (checkout?.id) {
			trackInitCheckout({
				checkoutId: checkout.id,
				lineItems: checkout.lineItems,
				subtotal: checkout.subtotal
			})
		}
	}, [checkout])

	// const onCheckoutClick = () => {
	// 	trackBeginCheckout({
	// 		checkoutId: checkout.id,
	// 		lineItems: checkout.lineItems,
	// 		subtotal: checkout.subtotal
	// 	})
	// }

	// CSS
	const style = {
		opacity: checkout ? 1 : 0,
		transition: 'opacity 0.5s'
	}
	
  return (
    <Layout>
			<section className="grid guttersx4 innerx2 header-offset" style={style}>
				<div className="span-8 start-5">
					<div className="cart__border inner grid">
						<div className="span-6 cart__title">
							<div className="upcase">Your Cart</div>
							{/* <a href="//shop.songforthemute.com/account" className="btn">Your orders</a> */}
						</div>
						<div className="span-6">
							<a href={checkoutLink} className="btn--bold cart__buy">Proceed to payment</a>
							<div className="p3 ul1 outerx2 has-margins">
								<RichText content={cartSmallPrint} />
							</div>
						</div>
					</div>

					<div className="cart__border grid inner mbx2">
						<div className="span-6"></div>
						<div className="span-6 grid">
							<div className="span-6">SUBTOTAL:</div>
							<div className="span-6">{subtotal}</div>
						</div>
					</div>

					<div className="cart__items">
						{checkout?.lineItems?.map((item, index) => (
							<LineItem {...item} handleRemove={handleRemove} key={item.id} />
						))}
					</div>
				</div>
			</section>
    </Layout>
  )
}

// Cart.propTypes = {
//   data: PropTypes.object.isRequired
// }

export default Cart