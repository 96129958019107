// Hooks & Helpers
import useVariantInfo from '../hooks/useVariantInfo'

function useLineItem(props) {

	// Props
	const { customAttributes, variant } = props

	// Helpers
	function $hasKey(item) { return item.key === this }
	const hasAttribute = (key, fallback) => customAttributes.find($hasKey, key)?.value || fallback

	// Content
	const { price } = useVariantInfo(variant)
	const size = variant.selectedOptions[0].value
	const title = hasAttribute('title', props.title)
	const handle = hasAttribute('handle', '')
	const colourMaterial = hasAttribute('colourMaterial', '')

	return { title, handle, colourMaterial, price, size }
}

export default useLineItem